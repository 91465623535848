.Error_container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../../statics/Bitmap.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .Error_content {
    padding: 0 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    padding-bottom: 4rem;
    .Content_text {
      padding-top: 4rem;
      &_big {
        margin-bottom: 2rem;
        span {
          font-size: 8rem;
          line-height: 0.5;
          color: white;
          font-weight: bold;
          font-family: var(--font-bold);
        }
      }

      &_normal {
        margin-bottom: 2rem;
        h1 {
          margin: 0;
          color: white;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 4rem;
          font-family: var(--font-bold);
        }

        h2 {
          margin: 0;
          color: white;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 1.75rem;
          font-family: var(--font-bold);
        }
      }

      &_button {
        width: 100%;
        a {
          text-decoration: none;
          text-transform: uppercase;
          text-align: center;
          background-color: #fddd10;
          color: white;
          width: 100%;
          max-width: 350px;
          display: block;
          margin: 0 auto;
          font-size: 18px;
          font-weight: bold;
          padding: 1rem 0;
          font-family: var(--font-bold);
        }
      }
    }

    .Content_img {
      display: none;
      img {
        height: 300px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .Error_container {
    .Error_content {
      margin-top: 120px;
      flex-direction: row;
      .Content_img {
        margin-left: 3rem;
        display: flex;
        img {
          height: 350px;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .Error_container {
    .Error_content {
      .Content_img {
        margin-left: 5rem;
        img {
          height: 450px;
        }
      }
    }
  }
}
