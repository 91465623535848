.ModalBases {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 330px;
  height: 700px;
}

.bases__modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(242, 273, 195, 0.9);
  border-radius: 20px;
  padding: 0.5rem;
  width: 100%;
  max-width: 500px;
  overflow: initial;
}

.bases__modal__wrap {
  border: 2px solid green;
  border-radius: 20px;
  width: 100%;
  p {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    right: 55px;
    text-decoration: none;
    width: 4px;
    height: 30px;
    color: green;
    font-weight: 600;
    font-family: 'kids-magazine', sans-serif;
    font-size: 20px;
  }
}

.bases__modal__sections {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 1rem 2rem 1rem;

  h3 {
    font-weight: 400;
    font-size: 2rem;
    color: #f2b800;
    margin: 1.5rem 0 -1.2rem 0;
  }
}
a {
  font-size: 1rem;
  color: green;
  margin: 0;
  line-height: 1.5;
  text-transform: uppercase;
}
span {
  font-weight: 800;
}
.droptext {
  font-size: 0.8rem;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .ModalBase {
  }

  .bases__modal {
    width: 130%;
  }

  .bases__modal__wrap {
    a {
      left: 685px;
    }
    p {
      width: 450px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .ModalBase {
  }

  .bases__modal {
    width: 130%;
  }

  .bases__modal__wrap {
    a {
      left: 685px;
    }
    p {
      width: 450px;
    }
  }
}
@media screen and (min-width: 1200px) {
  .ModalBase {
  }

  .bases__modal {
    width: 130%;
  }

  .bases__modal__wrap {
    a {
      left: 685px;
    }
    p {
      width: 860px;
    }
  }
}
