.Card__beer {
  border-radius: 20px;
  margin-bottom: 4rem;
  padding-top: 4rem;

  &__content {
    height: 190px;
    border-radius: 20px;
    margin-bottom: 1.75rem;
    &.green {
      background: rgb(0, 131, 0);
      background: linear-gradient(
        90deg,
        rgba(0, 131, 0, 1) 0%,
        rgba(16, 66, 28, 1) 85%
      );
    }

    &.blue {
      background: rgb(19, 99, 202);
      background: linear-gradient(
        90deg,
        rgba(19, 99, 202, 1) 0%,
        rgba(5, 69, 175, 1) 85%
      );
    }

    .beer__content__image {
      height: 100%;
      width: 100%;
      position: relative;
      img {
        height: 270px;
        position: absolute;
        left: 50%;
        bottom: 0.5rem;
        transform: translateX(-50%);
      }
    }
  }

  &__desc {
    display: flex;
    align-items: center;
    justify-content: center;
    &.green {
      .beer__desc__text {
        p {
          color: var(--text-primary);
        }
      }
    }

    &.blue {
      .beer__desc__text {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        span {
          margin: 0;
          font-family: var(--font-bold);
          letter-spacing: 0.5px;
          font-size: 20px;
          text-align: center;
          color: var(--bg-tertiary);
        }

        p {
          color: var(--bg-tertiary);
        }
      }
    }

    .beer__desc__text {
      p {
        margin: 0;
        font-family: var(--font-bold);
        letter-spacing: 0.5px;
        font-size: 20px;
        text-align: center;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .Card__beer {
    max-width: 48%;
    width: 100%;
    &__content {
      .beer__content__image {
        img {
          height: 300px;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .Card__beer {
    max-width: 48%;
    width: 100%;
    margin-bottom: 6rem;
    &__content {
      height: 250px;
      .beer__content__image {
        img {
          height: 350px;
        }
      }
    }

    &__desc {
      .beer__desc__text {
        span {
          font-size: 1.5rem;
          letter-spacing: unset;
        }

        p {
          font-size: 1.5rem;
          letter-spacing: unset;
        }
      }
    }
  }
}
