.Modal {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 330px;
  height: 700px;
}

.map__modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(242, 233, 195, 0.75);
  border-radius: 20px;
  padding: 0.5rem;
  width: 100%;
  max-width: 500px;
  overflow: initial;
}

.map__modal__wrap {
  border: 2px solid green;
  border-radius: 20px;
  width: 100%;

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    right: 55px;
    text-decoration: none;
    width: 30px;
    height: 30px;
    color: green;
    font-weight: 600;
    font-family: 'kids-magazine', sans-serif;
    font-size: 20px;
  }
}

.map__modal__section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 1rem 1rem 1rem;
}

h3 {
  font-weight: 400;
  font-size: 1.5rem;
  color: var(--text-secondary);
  margin: 0.5rem 0 0.8rem 0;
}

p {
  font-size: 1rem;
  color: var(--bg-secondary);
  margin: 0;
  line-height: 1.5;
}
span {
  font-weight: 800;
}

.droptext {
  font-size: 0.8rem;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .Modal {
  }

  .map__modal {
    width: 100%;
  }

  .map__modal__wrap {
    a {
      left: 840px;
    }
  }

  .map__modal__section {
  }

  h3 {
  }

  p {
  }

  .droptext {
  }
}
