.LaRoja__hero {
  &__content {
    .Hero__content {
      &__top {
        height: 500px;
        background-image: url('../../statics/images/Roja/banner-laroja.png');
        background-position: center 0rem;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
      }

      &__bottom {
        padding: 0rem 1rem;
        background-color: var(--bg-secondary);
        position: relative;
        &::before {
          content: '';
          position: absolute;
          z-index: 5;
          top: 0;
          left: 0;
          box-shadow: 0 -80px 50px 12px var(--bg-secondary);
          height: 100%;
          width: 100%;
          pointer-events: none;
        }

        .Content__bottom__center {
          &::after {
            content: '';
            position: absolute;
            z-index: 5;
            top: 0;
            left: 0;
            box-shadow: 0 -40px 50px 99px var(--bg-secondary);
            height: 100%;
            width: 100%;
            pointer-events: none;
          }
          .Bottom__center {
            &__logos {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding-top: 0px;
              img {
                max-width: 12rem;
              }
            }

            &__mapqatar {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding-top: 30px;
              img {
                max-width: 23rem;
              }
            }

            &__photo {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding-top: 30px;

              img {
                max-width: 23rem;
                height: 65px;
              }
              p {
                color: var(--text-secondary);
                text-shadow: 2px 2px 2px #000000;
                line-height: 1.3;
                font-size: 13px;
                text-align: center;
                padding: 0 1rem;
                font-family: 'Geomanist-Bold';
              }
            }
          }
        }
      }
    }
  }
}

.LaRoja {
  &__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;

    h3 {
      color: var(--text-secondary);
      text-shadow: 0px 1px 1px #000000;
      text-align: center;
      font-size: 28px;
      font-family: 'Geomanist-Bold';
    }

    p {
      text-align: center;
      color: #ffff;
      text-shadow: 0px 1px 1px #000000;
      font-size: 14px;
      font-family: 'Geomanist-Bold';
      margin: -6px;
    }
  }
  &__game {
    background-image: url(../../statics/images/Roja/sports-stadium.png);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    height: 580px;
    &::after {
      content: '';
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      box-shadow: 0 -40px 50px 99px var(--bg-secondary);
      height: 100%;
      width: 100%;
      pointer-events: none;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2px;
      padding: 3rem 0;
      min-height: 300px;
      z-index: 6;
      position: relative;

      .change {
        &--1 {
          background-color: #014701de;
          color: white;
          height: 100px;
          width: 100%;
        }

        &--2 {
          background-color: #014701de;
          color: white;
          height: 100px;
          width: 100%;
        }

        &--3 {
          background-color: #014701de;
          color: white;
          height: 100px;
          width: 100%;
        }

        &--4 {
          background-color: #014701de;
          color: white;
          height: 100px;
          width: 100%;
        }

        &__content {
          font-family: sans-serif;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;

          &--left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 50%;
            max-width: 182px;
            position: relative;

            &:after {
              content: '-';
              position: absolute;
              top: 0;
              right: -10px;
              font-size: 3rem;
              font-family: 'Geomanist-Bold';
              font-weight: 600;
            }
          }

          &--right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 50%;
            max-width: 200px;
            position: relative;

            &:before {
              content: '/';
              position: absolute;
              bottom: 0;
              left: -1px;
            }
          }

          &--top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 65%;
            padding-bottom: 0.5rem;

            span {
              font-size: 3rem;
              font-family: 'Geomanist-Bold';
              font-weight: 600;
            }

            img {
              width: 40px;
              height: auto;
            }
          }

          &--top:nth-child(2n) {
            // no está funcionando, hay que revisarlo (es para que tome solo a los elementos pares)
            width: 58%;
          }

          &--bottomL {
            text-align: start;
            font-size: 0.65rem;
            color: white;
            margin: 0;
            line-height: 1.5;
            font-weight: 600;
            font-family: 'Geomanist-Bold';
            width: 65%;
          }

          &--bottomR {
            width: 100%;
            text-align: start;
            padding-left: 1.8rem;
            font-size: 0.8rem;
            color: white;
            margin: 0;
            line-height: 1.5;
            font-weight: 300;
            font-family: 'Geomanist';
          }
        }

        &__winner {
          color: var(--text-secondary);
        }
      }
    }
  }
}
.LaRoja {
  &__footer {
    height: 50px;
    padding: 0rem 1rem;
    background-color: var(--bg-primary);
    background-position: center;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    &::before {
      content: '';
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      box-shadow: 0 -30px 18px 15px var(--bg-primary);
      height: 100%;
      width: 100%;
      pointer-events: none;
    }
  }
}
////////////////////////

@media screen and (min-width: 768px) {
  .LaRoja__hero {
    &__content {
      .Hero__content {
        &__bottom {
          &::before {
            box-shadow: 0 30px 50px 120px var(--bg-secondary);
          }
          .Content__bottom__center {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .Bottom__center__logos {
              max-width: 47%;
              width: 50%;
              img {
                max-width: 20rem;
              }
            }

            .Bottom__center__mapqatar {
              max-width: 47%;
              width: 100%;
              img {
                max-width: 70rem;
                height: 225px;
              }
            }
            .Bottom__center__photo {
              //max-width: 47%;
              width: 100%;
              img {
                max-width: 70rem;
                height: 125px;
              }
              p {
                font-size: 30px;
              }
            }
          }
        }
      }
    }
  }

  .LaRoja {
    height: 750px;
    &__title {
      h3 {
        font-size: 50px;
        margin-top: 100px;
        padding: 2px;
      }
      p {
        font-size: 25px;
      }
    }
    &__game {
      &__content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .change {
          &--1 {
            height: 110px;
          }

          &--2 {
            height: 110px;
          }

          &--3 {
            height: 110px;
          }

          &--4 {
            height: 110px;
          }

          &__content {
            &--left {
              &:after {
              }
            }

            &--right {
              &:before {
              }
            }

            &--top {
              span {
              }

              img {
                width: 45px;
                height: auto;
              }
            }

            &--top:nth-child(2n) {
            }

            &--bottomL {
              font-size: 13px;
            }

            &--bottomR {
            }
          }

          &__winner {
          }
        }
      }
    }
  }

  .LaRoja__footer {
      height: 150px;
      &::before {
        box-shadow: 0 -40px 70px 50px var(--bg-primary);
      }
  }
}

@media screen and (min-width: 1024px) {
  .LaRoja__hero {
    &__content {
      .Hero__content {
        &__top {
          background-image: url('../../statics/images/Roja/banner-laroja.png');
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }

        &__bottom {
          .Content__bottom__center {
            .Bottom__center__logos {
            }

            .Bottom__center__mapqatar {
            }
            .Bottom__center__photo {
              img {
                max-width: 70rem;
                height: 130px;
              }
              p {
                font-size: 30px;
              }
            }
          }
        }
      }
    }
  }

  .LaRoja {
    height: 780px;
    &__game {
      height: 830px;
      &__content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .change {
          &--1 {
            height: 120px;
          }

          &--2 {
            height: 120px;
          }

          &--3 {
            height: 120px;
          }

          &--4 {
            height: 120px;
          }
        }
      }
    }
  }
  .LaRoja {
      &__footer {
      height: 150px;
      ::before {
        box-shadow: 0 -40px 60px 11px var(--bg-primary);
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .LaRoja__hero {
    &__content {
      .Hero__content {
        &__top {
          height: 730px;
        }
        &__bottom {
          .Content__bottom__text {
            max-width: 1200px;
            margin: 0 auto;
          }
        }
      }
    }
  }
  .LaRoja {
      &__footer {
      height: 150px;
      ::before {
        box-shadow: 0 -40px 15px 11px var(--bg-primary);
      }
    }
  }
  .LaRoja {
    &__game {
      height: 840px;
    }
  }
}
