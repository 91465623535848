.Home__hero {
  height: 100vh;
  &__swiper {
    height: 100%;
    position: relative;
    overflow: hidden;
    background-color: var(--bg-primary);
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      z-index: 8;
      box-shadow: 0 -20px 30px 10px var(--bg-primary);
      // box-shadow: 0 -20px 100px 85px var(--bg-primary);
      height: 100%;
      pointer-events: none;
    }
  }
}

.Home__gallery {
  padding: 2rem 1rem 0;
  padding-bottom: 0rem;
  background-color: var(--bg-primary);
  &__content {
    .Gallery__content__grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 400px 200px 400px 200px;
      gap: 0.5em 0px;
      grid-template-areas:
        '.'
        '.'
        '.'
        '.'
        '.'
        '.'
        '.'
        '.'
        '.'
        '.'
        '.'
        '.'
        '.'
        '.'
        '.'
        '.'
        '.'
        '.'
        '.'
        '.';

      .Content__grid__card {
        border-radius: 20px;
        position: relative;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        &:nth-child(5) {
          padding-bottom: 175px;
        }
        &:nth-child(6) {
          padding-bottom: 175px;
        }
        &:nth-child(7) {
          padding-bottom: 175px;
        }
        &:nth-child(8) {
          padding-bottom: 175px;
        }
        &.red {
          .Grid__card__text {
            .Card__text__badge {
              &::after {
                background-color: red;
              }
            }
          }
        }

        .Grid__card__text {
          padding: 1rem;
          position: absolute;
          bottom: 0;
          width: 100%;
          .Card__text__badge {
            padding: 0.15rem 0.5rem;
            width: max-content;
            position: relative;
            &::after {
              left: 0;
              top: 0;
              position: absolute;
              content: '';
              width: 100%;
              height: 100%;
              border-radius: 25px;
              background-color: var(--bg-secondary);
              mix-blend-mode: multiply;
            }
            label {
              z-index: 2;
              position: relative;
              font-size: 14px;
              color: var(--text-secondary);
            }
          }

          h1 {
            margin-bottom: 0;
            margin-top: 0.75rem;
            font-size: 1.75rem;
            color: var(--white);
            font-family: var(--font-bold);
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .Home {
    &__hero {
      &__swiper {
        &::after {
          box-shadow: 0 -5px 50px 35px var(--bg-primary);
        }
      }
    }

    &__gallery {
      padding: 2rem 1rem 0;
      &__content {
        padding-bottom: 6rem;
        .Gallery__content__grid {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(3, 1fr);
          grid-column-gap: 15px;
          grid-row-gap: 10px;
          grid-template-areas: unset;
          .Content__grid__card {
            &:first-child {
              grid-area: 1 / 1 / 3 / 3;
              height: 400px;
            }

            &:nth-child(2) {
              grid-area: 1 / 3 / 2 / 5;
              height: 190px;
            }

            &:nth-child(3) {
              grid-area: 2 / 3 / 3 / 5;
              height: 190px;
            }

            &:nth-child(4) {
              grid-area: 3 / 1 / 3 / 3;
            }

            &:nth-child(5) {
              grid-area: 3 / 3 / 3 / 5;
            }

            &:nth-child(6) {
              grid-area: 4 / 3 / 30 / 5;
            }

            &:nth-child(7) {
              grid-area: 4 / 1 / 30 / 3;
            }

            &:nth-child(8) {
              grid-area: 30 / 1 / 50 / 5;
            }

            &:nth-child(9) {
              grid-area: 5 / 3 / 6 / 5;
            }

            .Grid__card__text {
              padding: 0.75rem 0.5rem;
              position: absolute;
              bottom: 0;
              width: 100%;
              h1 {
                font-size: 1.1rem;
                letter-spacing: 0.25px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .Home {
    &__gallery {
      padding: 4rem 1rem 0;
      &__content {
        padding-bottom: 8rem;
        .Gallery__content__grid {
          grid-column-gap: 30px;
          grid-row-gap: 30px;
          .Content__grid__card {
            &:first-child {
              height: 600px;
            }

            &:nth-child(2) {
              height: 285px;
            }

            &:nth-child(3) {
              height: 285px;
            }

            .Grid__card__text {
              padding: 1rem;
              h1 {
                font-size: 1.5rem;
                letter-spacing: 0.25px;
              }
            }
          }
        }
      }
    }
  }
}
