.Campaings {
  &__hero {
    &__content {
      .Campaings__content {
        &__top {
          height: 500px;
          background-image: url('../../statics/images/NuestrasCervezas//product-mobile.png');
          background-position: center -5rem;
          background-size: cover;
          background-repeat: no-repeat;
          position: relative;
        }

        &__bottom {
          padding: 0rem 1rem;
          background-color: var(--bg-secondary);
          position: relative;
          &::before {
            content: '';
            position: absolute;
            z-index: 5;
            top: 0;
            left: 0;
            box-shadow: 0 -80px 50px 70px var(--bg-secondary);
            height: 100%;
            width: 100%;
            pointer-events: none;
          }
          .Content__bottom__text {
            .Bottom__text {
              &__center {
                span {
                  color: var(--white);
                  font-size: 20px;
                  margin-bottom: 0;
                  line-height: 1;
                  letter-spacing: 0.5px;
                }

                h1 {
                  color: var(--text-secondary);
                  font-family: var(--font-bold);
                  font-size: 3rem;
                  letter-spacing: 0.5px;
                  margin: 1.5rem 0;
                  line-height: 0.9;
                }

                p {
                  color: var(--white);
                  line-height: 1.6;
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
    }
  }

  &__timeline {
    z-index: 5;
    position: relative;
    background-color: var(--bg-secondary);
    padding: 2rem 1rem;
    &__content {
      padding-bottom: 4rem;
      .Campaings__timeline__list {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          left: 1rem;
          top: 1rem;
          height: calc(100% - 1rem);
          width: 2px;
          background-color: var(--white);
        }

        li {
          margin-bottom: 4rem;
          &:last-child {
            margin-bottom: 0;
          }

          .Timeline__content {
            &__rigth {
              .Card__timeline {
                .Card__content {
                  &__text {
                    margin-left: 4rem;
                    margin-bottom: 1.75rem;
                    span {
                      font-size: 2rem;
                      font-family: var(--font-bold);
                      color: var(--text-secondary);
                      position: relative;
                      &::before {
                        content: '';
                        position: absolute;
                        left: -3.75rem;
                        top: 50%;
                        transform: translateY(-50%);
                        height: 25px;
                        width: 25px;
                        background-color: var(--white);
                        border-radius: 50%;
                      }
                    }

                    h2 {
                      margin: 0;
                      margin-top: 0.35rem;
                      font-size: 18px;
                      color: var(--white);
                    }
                  }

                  &__img {
                    height: 180px;

                    img {
                      border-radius: 20px;
                      height: 100%;
                      max-width: 100%;
                      width: 100%;
                      object-fit: cover;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .Campaings {
    &__hero {
      &__content {
        .Campaings__content {
          &__top {
          }

          &__bottom {
            padding: 0 2rem;
            .Content__bottom__text {
              .Bottom__text {
                &__center {
                  p {
                    max-width: 65%;
                  }
                }
              }
            }
          }
        }
      }
    }

    &__timeline {
      padding: 2rem;
      &__content {
        .Campaings__timeline__list {
          &::before {
            left: 50%;
            transform: translateX(-50%);
            height: calc(100% - 250px);
          }
          li {
            max-width: 43%;
            margin-bottom: 0;
            &.rigth {
              margin-left: auto;
            }

            &.left {
              .Timeline__content {
                &__rigth {
                  .Card__timeline {
                    .Card__content {
                      &__text {
                        text-align: right;
                        span {
                          &::before {
                            left: unset;
                            right: -3.75rem;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .Timeline__content {
              &__rigth {
                .Card__timeline {
                  .Card__content {
                    &__text {
                      margin-left: 0;
                      margin-bottom: 0.5rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .Campaings {
    &__hero {
      &__content {
        .Campaings__content {
          &__top {
            background-image: url('../../statics/images/Home/hero-desktop.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }

    &__timeline {
      padding-top: 4rem;
      &__content {
        .Campaings__timeline__list {
          &::before {
            width: 3px;
            height: calc(100% - 300px);
          }

          li {
            max-width: 45%;
            margin-top: -6rem;
            &:first-child {
              margin-top: 0rem;
            }
            &.rigth {
              .Timeline__content {
                &__rigth {
                  .Card__timeline {
                    .Card__content {
                      &__text {
                        span {
                          margin-right: 10px;
                          &::before {
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            &.left {
              .Timeline__content {
                &__rigth {
                  .Card__timeline {
                    .Card__content {
                      &__text {
                        justify-content: flex-start;
                        flex-direction: row-reverse;
                        span {
                          margin-left: 10px;
                          &::before {
                            right: -4.25rem;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            &:last-child {
              margin-bottom: 0;
            }

            .Timeline__content {
              &__rigth {
                .Card__timeline {
                  .Card__content {
                    &__text {
                      display: flex;
                      flex-direction: row;
                      span {
                        &::before {
                          left: -4.3rem;
                          height: 40px;
                          width: 40px;
                        }
                      }

                      h2 {
                      }
                    }

                    &__img {
                      height: 250px;

                      img {
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .Campaings {
    &__hero {
      &__content {
        .Campaings__content {
          &__top {
            height: 550px;
          }

          &__bottom {
            .Content__bottom__text {
              max-width: 1200px;
              margin: 0 auto;
              padding: 0;
              .Bottom__text {
                &__center {
                  p {
                    max-width: 65%;
                  }
                }
              }
            }
          }
        }
      }
    }

    &__timeline {
      padding-top: 4rem;
      &__content {
        .Campaings__timeline__list {
          max-width: 1200px;
          margin: 0 auto;
          &::before {
            width: 3px;
            height: calc(100% - 300px);
          }

          li {
            max-width: 45.8%;
            margin-top: -6rem;
          }
        }
      }
    }
  }
}
