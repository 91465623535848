.Together {
  &__hero {
    &__content {
      .Together__content {
        &__top {
          height: 580px;
          background-image: url('../../statics/images/Juntemonos/bannerMusic.jpg');
          background-position: center -5rem;
          background-size: cover;
          background-repeat: no-repeat;
          position: relative;
        }

        &__bottom {
          padding: 0rem 1rem;
          background-color: var(--bg-secondary);
          position: relative;
          &::before {
            content: '';
            position: absolute;
            z-index: 5;
            top: 0;
            left: 0;
            box-shadow: 0 -73px 50px 70px var(--bg-secondary);
            height: 100%;
            width: 100%;
            pointer-events: none;
          }
          .Content__bottom__text {
            padding: 1px;
            .Bottom__text {
              &__center {
                span {
                  color: var(--text-secondary);
                  font-size: 20px;
                  margin-bottom: 0;
                  line-height: 1;
                  letter-spacing: 0.5px;
                }

                h1 {
                  color: var(--text-secondary);
                  font-family: var(--font-bold);
                  text-align: center;
                  font-size: 2rem;
                  letter-spacing: 0.5px;
                  margin: 0.5rem 0;
                  line-height: 0.9;
                }

                p {
                  color: var(--white);
                  line-height: 1.6;
                  font-size: 18px;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }
}
.container__videos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  background-color: var(--bg-secondary);
  padding-bottom: 0px;
  padding-top: 20px;
  margin-top: -1px;
  &::after {
    content: '';
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    box-shadow: 0 -80px 50px 70px var(--bg-secondary);
    height: 100%;
    width: 100%;
    pointer-events: none;
  }
}

.container__videos__card {
  background-color: white;
  box-sizing: border-box;
  padding: 5px;
}

.card__video {
  .wrapresponsive {
    iframe {
      width: 100%;
      border: none;
    }
  }
}

.card__text__info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.card__text h4,
.card__text h5 {
  padding-left: 5px;
  margin: 0.2rem 0;
  color: black;
}

.card__text__info {
  padding-left: 5px;
}

.card__text__info p {
  margin: 0 0 1rem;
  color: black;
}

.card__text__info p:nth-child(2) {
  margin-left: 1rem;
}

.container__video {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  background-color: var(--bg-secondary);
  padding-bottom: 46px;
  padding-top: 0px;
  opacity: 1;
  font-size: inherit;
  max-height: 999em;
  &::after {
    content: '';
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    box-shadow: 0 -80px 50px 70px var(--bg-secondary);
    height: 100%;
    width: 100%;
    pointer-events: none;
  }
}

.container__video__card {
  background-color: white;
  box-sizing: border-box;
  padding: 5px;
}

.card__videos {
  iframe {
    width: 100%;
    border: none;
  }
}

.card__text__info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.card__text h4,
.card__text h5 {
  padding-left: 5px;
  margin: 0.2rem 0;
  color: black;
  font-family: var(--font-bold);
}

.card__text__info {
  padding-left: 5px;
}

.card__text__info p {
  margin: 0 0 1rem;
  color: black;
}

.card__text__info p:nth-child(2) {
  margin-left: 1rem;
}
.Together {
  &__sector {
    height: 50px;
    padding: 0rem 1rem;
    background-color: var(--bg-secondary);
    background-position: center;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    opacity: 1;
    font-size: inherit;
    max-height: 999em;
    button {
      position: absolute;
      bottom: 0;
      right: 0;
      display: block;
      width: 100%;
      height: 60px;
      text-align: center;
      color: blue;
      font-weight: bold;
      font-size: 16px;
      padding-top: 40px;
      background-image: linear-gradient(to bottom, transparent, white);
      cursor: pointer;
    }
    &::before {
      content: '';
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      box-shadow: 0 -30px 18px 15px var(--bg-secondary);
      height: 100%;
      width: 100%;
      pointer-events: none;
    }
  }
}

.Together {
  &__footer {
    height: 80px;
    margin-top: 5px;
    padding: 0rem 1rem;
    background-color: var(--bg-primary);
    background-position: center;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    &::before {
      content: '';
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      box-shadow: 0 -30px 18px 15px var(--bg-primary);
      height: 100%;
      width: 100%;
      pointer-events: none;
    }
  }
}

@media screen and (min-width: 768px) {
  .Together {
    &__hero {
      &__content {
        .Together__content {
          &__top {
          }

          &__bottom {
            padding: 0 2rem;
            .Content__bottom__text {
              padding: 1px;
              .Bottom__text {
                &__center {
                  h1 {
                    font-size: 3rem;
                  }
                  p {
                    text-align: center;
                    font-size: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .container__videos__card {
    padding: 10px; /*esto solo en desktop!*/
  }
  .container__video__card {
    padding: 10px;
  }
}

@media screen and (min-width: 1024px) {
  .Together {
    &__hero {
      &__content {
        .Together__content {
          &__top {
            background-image: url('../../statics/images/Juntemonos/bannerMusic.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }

    &__videos {
      padding-top: 4rem;
      &__content {
        .container__videos__card {
          padding-right: 10px; /*esto solo en desktop!*/
        }

        .Together__videos__list {
          &::before {
            width: 3px;
            height: calc(100% - 300px);
          }

          li {
            max-width: 45%;
            margin-top: -6rem;
            &:first-child {
              margin-top: 0rem;
            }
            &.rigth {
              .Videos__content {
                &__rigth {
                  .Card__videos {
                    .Card__content {
                      &__text {
                        span {
                          margin-right: 10px;
                          &::before {
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .Videos__content {
              &__rigth {
                .Card__videos {
                  .Card__content {
                    &__text {
                      display: flex;
                      flex-direction: row;
                      span {
                        &::before {
                          left: -4.3rem;
                          height: 40px;
                          width: 40px;
                        }
                      }

                      h2 {
                      }
                    }

                    &__img {
                      height: 250px;

                      img {
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .container__videos {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 1200px) {
  .Together {
    &__hero {
      &__content {
        .Together__content {
          &__top {
            height: 550px;
          }

          &__bottom {
            .Content__bottom__text {
              max-width: 1200px;
              margin: 0 auto;
              padding: 1px;
              .Bottom__text {
                &__center {
                  p {
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }
    }

    &__videos {
      padding-top: 4rem;
      &__content {
        .Together__videos__list {
          max-width: 1200px;
          margin: 0 auto;
          &::before {
            width: 3px;
            height: calc(100% - 300px);
          }

          li {
            max-width: 45.8%;
            margin-top: -6rem;
          }
        }
      }
    }
  }
}
