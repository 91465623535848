.origenes {
  position: relative;
  &-img {
    width: 100%;
    height: auto;
    &-desk {
      display: none;
    }
  }

  .bottom {
    .container-buttons-legal {
      position: absolute;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      .buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
      }

      .legal {
        display: flex;

        justify-content: center;
        align-items: center;

        &-desk {
          display: none;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .origenes {
    &-img {
      &-desk {
        display: block;
        &-top {
          padding-top: 7rem;
        }
      }

      &-mb {
        display: none;
      }
    }

    .bottom {
      .container-buttons-legal {
        .buttons {
          flex-direction: row;
        }

        .legal {
          padding: 1rem 0 3rem;
          &-desk {
            display: block;
          }

          &-mb {
            display: none;
          }
        }
      }
    }
  }
}
