.TeamVerano__hero {
  z-index: 2;
  position: relative;
  &__content {
    .Hero__content {
      &__top {
        height: 190px;
        background-image: url('../../../statics/images/TeamCristal/header.png');
        background-position: top center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
      }
      &__bottom {
        height: 350px;
        padding: 0rem 1rem;
        background: url(../../../statics/images/TeamCristal/background-green.png);
        background-position: top center;
        background-size: cover;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .Content__bottom__logo {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .Bottom__logo {
            &__center {
              position: absolute;
              top: -50px;

              img {
                max-width: 8rem;
              }
            }
            &__text {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding-top: 40px;

              span {
                color: var(--white);
                font-size: 20px;
                margin-bottom: 0;
                line-height: 1;
                letter-spacing: 0.5px;
              }

              h1 {
                color: var(--text-secondary);
                text-shadow: 2px 2px 2px #000000;
                font-family: var(--font-bold);
                font-size: 3rem;
                letter-spacing: 0.5px;
                margin-bottom: 0;
                margin-top: 0.5em;
                text-align: center;
                line-height: 0.9;
              }

              p {
                color: var(--white);
                line-height: 1.3;
                font-size: 16px;
                text-align: center;
                padding: 0 1rem;
              }
            }
          }
        }
      }
    }
  }
}

.TeamVerano {
  position: relative;
  z-index: -1;
  &__map {
    &__wrap {
      overflow: hidden;
      padding: 6rem 1rem 3rem;
      margin-top: -130px;
      min-height: 500px;
      background-color: var(--bg-primary);
    }
  }
}
.App {
  font-family: sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.map {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  background-color: transparent;
  width: 100%;
  position: relative;
}

.map__wrap {
  width: 60%;
  max-width: 300px;
  position: relative;
}

.map__wrap img {
  width: 100%;
  height: auto;
}

.map__points {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 180px;
  left: -100px;
  height: 65%;
  width: 70%;
}

.map__point {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 20px;
  width: 100%;
  margin-top: 3rem;
  cursor: pointer;
}

.map__point h5:hover {
  border: 1px solid #de9e27;
  border-radius: 5px;
  color: #de9e27;
  font-size: 1.1rem;
  padding: 0.5rem;
}

.map__point--iquique {
  transform: translate(-5px, 15px);
}

.map__point--antofagasta {
  transform: translate(-5px, -25px);
}

.map__point--coquimbo {
  transform: translate(-5px, -25px);
  width: 240px;
}

.map__point--viña {
  transform: translate(0, -100px);
}

.map__point--santiago {
  transform: translate(45px, -153px);
}

.map__point--pucon {
  transform: translate(0, 40px);
}

.map__point--ptoVaras {
  transform: translate(-12px, -78px);
}

.map__point h5 {
  margin: 0 0 1rem 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.8rem;
}

.map__point h5::after {
  content: ' ';
  width: 15px;
  height: 15px;
  background-image: url(https://user-images.githubusercontent.com/55818246/147479262-65db0adc-e429-498e-b124-15e7d2c1ca63.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 1rem;
}

.map__photos {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 220px;
  left: -70px;
}

.map__photos button {
  cursor: pointer;
  background: transparent;
  border: none;
  width: 45%;
  max-width: 300px;
}

.map__photos button img {
  width: 100%;
  height: auto;
}

.modalSucess {
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: none;
  justify-content: center;
  align-items: center;
  overflow: auto;

  &.active {
    display: flex;
  }
}

.Map__wrap__cam {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  bottom: 260px;
  width: 100%;
  height: 100%;
  a {
    img {
      max-width: 12rem;
      z-index: 4;
    }
  }
}
.Map__wrap__footer {
  height: 150px;
  padding: 0rem 1rem;
  background: url(../../../statics/images/TeamCristal/background-2-teamverano-min.png);
  background-position: center;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 100%;
    bottom: 0;
    height: auto;
    padding: 0;
    position: absolute;
    max-width: 1400px;
    left: 0;
  }
}
@media screen and (min-width: 768px) {
  .TeamVerano__hero {
    &__content {
      .Hero__content {
        &__top {
          height: 450px;
        }
        &__bottom {
          height: 415px;
          padding: 2rem 1rem;
          background-position: bottom;

          .Content__bottom__logo {
            .Bottom__logo {
              &__center {
                top: -50px;

                img {
                  max-width: 12rem;
                  margin-top: 70px;
                }
              }
              &__text {
                padding-top: 40px;

                h1 {
                  font-size: 3rem;
                  position: sticky;
                  padding: 3.5rem 1rem 0;
                  text-align: center;
                }

                p {
                  font-size: 1.5rem;
                  padding: 1.5rem 1rem 0;
                }
              }
            }
          }
        }
      }
    }
  }
  .Content__footer {
    height: 441px;

    img {
      width: 100%;
    }
  }

  .TeamVerano {
    &__map {
      &__wrap {
        padding: 15rem 1rem 0;
        margin-top: -370px;
        min-height: 500px;
      }
    }
  }

  .map {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    width: 100%;
    position: relative;
    z-index: 12;
  }

  .map__wrap {
    width: 60%;
    max-width: 300px;
    position: relative;
  }

  .map__wrap img {
    width: 100%;
    height: auto;
  }

  .map__points {
    z-index: 5;
    height: 55%;
    width: 60%;
  }

  .map__point {
    height: 20px;
    width: 100%;
    margin-top: 3rem;
  }

  .map__point h5:hover {
    font-size: 2rem;
  }

  .map__point--iquique {
    transform: translate(-5px, 15px);
  }

  .map__point--antofagasta {
    transform: translate(-5px, -25px);
  }

  .map__point--coquimbo {
    transform: translate(-5px, -25px);
    width: 300px;
  }

  .map__point--viña {
    transform: translate(0, -100px);
    width: 300px;
  }

  .map__point--santiago {
    transform: translate(55px, -25px);
  }

  .map__point--pucon {
    transform: translate(0, 40px);
  }

  .map__point--ptoVaras {
    transform: translate(-65px, -45px);
    width: 300px;
  }

  .map__point h5 {
    font-size: 1.5rem;
  }

  .map__point h5::after {
    width: 25px;
    height: 25px;
  }

  .map__photos {
    width: 100%;
    bottom: 220px;
    left: -70px;
  }

  .map__photos button {
    width: 45%;
    max-width: 300px;
  }

  .map__photos button img {
    width: 100%;
    height: auto;
  }

  .Map__wrap__cam {
    bottom: 340px;
    left: 100px;
    a {
      img {
        max-width: 15rem;
        z-index: 4;
      }
    }
  }
  .Map__wrap__footer {
    height: 480px;
  }
}
