@import-normalize;
@import './fonts';

:root {
  box-sizing: border-box;

  --white: #ffffff;

  --text-primary: #008212;
  --text-secondary: #ffcb27;
  --text-tertiary: #2262bd;

  --bg-primary: #f2e9c3;
  --bg-secondary: #008300;
  --bg-tertiary: #005dc6;

  --font: 'Geomanist';
  --font-bold: 'Geomanist-Bold';

  --font-secondary: 'Myriad';
  --font-secondary-bold: 'Myriad-Bold';
  --font-secondary-semiBold: 'Myriad-SemiBold';
}

*,
::before,
::after {
  box-sizing: inherit;
}

body {
  padding: 0;
  margin: 0;
  font-family: var(--font);
  font-size: 16px;
  color: var(--text-primary);

  &.validate {
    overflow: hidden;
  }
}

#root {
  position: relative;
}
