.Badge__warning {
  position: relative;

  .warning__container {
    position: absolute;
    top: 0;
    right: 0;
    width: 20vw;
    height: auto;
    z-index: 2;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  @media (max-width: 768px) {
    .warning__container {
      top: 70px;
    }
  }
}
