.Hero__swiper {
  .swiper-pagination {
    bottom: unset;
    top: 530px;
    text-align: end;
    padding: 0 1rem;
    &-bullet {
      background-color: var(--text-secondary);
      opacity: 1;
      height: 10px;
      width: 10px;
      &-active {
        background-color: var(--bg-secondary);
      }
    }
  }

  .swiper-wrapper {
    .swiper-slide {
      height: 100vh;
      background-position: center 0;
      background-size: cover;
      background-repeat: no-repeat;
      cursor: grab;

      // &:first-child {
      //   background-position: center bottom;
      // }

      .Hero__swiper__content {
        padding: 0 1rem;
        padding-top: 135px;
        height: 100%;
        .Swiper__content__text {
          h1 {
            text-decoration: none;
            margin-top: 0;
            font-size: 2.75rem;
            text-align: right;
            text-transform: uppercase;
            margin-bottom: 0;
            font-family: var(--font-bold);
            color: var(--text-secondary);
          }

          h2 {
            text-decoration: none;
            margin: 0;
            text-align: right;
            max-width: 55%;
            line-height: 1.2;
            letter-spacing: 0.25px;
            font-size: 16px;
            width: 100%;
            margin-left: auto;
            font-family: var(--font-bold);
            color: var(--text-secondary);
          }

          p {
            display: none;
            color: white;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .Hero__swiper {
    .swiper-pagination {
      top: unset;
      bottom: 10rem;
      padding: 0 3rem;
    }

    .swiper-wrapper {
      .swiper-slide {
        .Hero__swiper__content {
          padding: 0 3rem;
          padding-top: 15rem;
          .Swiper__content__text {
            h1 {
              font-size: 4rem;
              letter-spacing: 1px;
            }

            h2 {
              max-width: 40%;
              font-size: 1.5rem;
            }

            p {
              text-align: right;
              max-width: 50%;
              margin-left: auto;
              display: block;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .Hero__swiper {
    .swiper-pagination {
      top: unset;
      bottom: 8rem;
      padding: 0 3rem;
    }

    .swiper-wrapper {
      .swiper-slide {
        .Hero__swiper__content {
          padding: 0 2rem;
          padding-top: 9rem;
          .Swiper__content__text {
            h1 {
              font-size: 4rem;
              letter-spacing: 1px;
            }

            h2 {
              max-width: 30%;
              font-size: 1.5rem;
            }

            p {
              font-size: 1.15rem;
              max-width: 35%;
            }
          }
        }
      }
    }
  }
}
