.ThirdTime {
  &__hero {
    &__content {
      .ThirdTime__content {
        &__top {
          height: 440px;
          background-image: url('../../statics/images/TercerTiempo/banner-3ertiempo.jpg');
          background-position: center 0rem;
          background-size: cover;
          background-repeat: no-repeat;
          position: relative;
        }

        &__bottom {
          padding: 1rem 1rem;
          background-color: var(--bg-secondary);
          position: relative;
          margin-top: -60px;
          &::before {
            content: '';
            position: absolute;
            z-index: 5;
            top: 0;
            left: 0;
            box-shadow: 0 5px 50px 70px var(--bg-secondary);
            height: 100%;
            width: 100%;
            pointer-events: none;
          }
          .Content__bottom__text {
            .Bottom__text {
              &__center {
                span {
                  color: var(--white);
                  font-size: 20px;
                  margin-bottom: 0;
                  line-height: 1;
                  letter-spacing: 0.5px;
                }

                h1 {
                  color: var(--text-secondary);
                  font-family: var(--font-bold);
                  font-size: 1.5rem;
                  letter-spacing: -0.5px;
                  margin: 0.5rem 0;
                  line-height: 0.9;
                  text-align: center;
                }

                p {
                  color: var(--white);
                  line-height: 1.6;
                  font-size: 18px;
                  text-align: center;
                }
                span {
                  color: var(--text-secondary);
                }
              }
            }
          }
        }
      }
    }
  }
  &__custom {
    position: relative;
    background-color: rgb(254, 235, 192);
    width: 100%;
    height: 550px;
    &__content {
      .Custom__content__photo {
        z-index: 5;
        margin-top: 30px;
        display: flex;
        img {
          z-index: 5;
          width: 100%;
          height: 100%;
          top: 8px;
          padding: 15px;
        }
      }
      .Custom__content__textInfo {
        padding: 15px;
        margin: 10px;
        p {
          line-height: 1.6;
          font-size: 18px;
          text-align: center;
          align-items: center;
          margin-top: 10px;
        }
      }
    }
  }

  &__bg {
    min-height: 100vh;
    background: rgb(254, 235, 192);
    background: linear-gradient(
      180deg,
      rgba(254, 235, 192, 1) 25%,
      rgba(11, 132, 1, 1) 25%
    );
    .ThirdTime__ligas {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        font-family: var(--font-bold);
        text-align: center;
        font-weight: 800;
        font-size: 1.5rem;
        text-transform: uppercase;
        color: #0b8401;
        margin: 0;

        span {
          color: #ffcb27;
        }
      }

      .ligas__table {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin: 0 1rem;
        width: 90%;
        max-width: 700px;

        table {
          font-family: arial, sans-serif;
          border-collapse: collapse;
          width: 100%;
          box-shadow: 0px 0px 10px 2px #00000040;
          border-bottom: 15px solid #ffcb27;
          margin: 1rem 0;
        }

        th {
          font-size: 1rem;
          background-color: #0b8401;
          color: white;
          text-transform: uppercase;
        }

        td,
        th {
          font-size: 0.8rem;
          text-align: left;
          padding: 10px 4px;
        }

        td img {
          width: 35px;
          padding: 0 0.5rem;
        }

        td {
          color: black;
        }

        td:nth-child(3) {
          font-weight: 900;
          color: #0b8401;
        }

        table:nth-child(odd) tr {
          background-color: #ffffff;
        }

        table:nth-child(even) tr {
          background-color: #dadada;
        }
      }
    }
  }

  &__ig {
    position: relative;
    background-color: #0b8401;
    width: 100%;
    height: auto;
    padding: 10px;
    &__content {
      .ThirdTime__ig__textTitle {
        h1 {
          color: white;
          font-family: var(--font-bold);
          font-size: 1.5rem;
          letter-spacing: -0.5px;
          margin: 1.5rem 0;
          line-height: 0.9;
          text-align: center;
        }
        span {
          color: var(--text-secondary);
        }
      }
      .ThirdTime__ig__gallery {
        display: flex;
        flex-wrap: wrap;

        .igImg {
          width: 33.33%;
          border: 0px dotted lightgrey;
          padding: 0px;
          margin: 0px;

          img {
            width: 100%;
            height: 100%;
            padding: 10px;
          }
        }
        .igImgClick {
          width: 33.33%;
          border: 0px dotted lightgrey;
          padding: 0px;
          margin: 0px;
          align-items: center;
          display: flex;
          position: relative;
          left: 125px;
          a {
            font-family: var(--font-secondary-bold);
            color: var(--white);
            background-color: transparent;
            border: 2px solid var(--white);
            padding: 0.5rem 0;
            display: block;
            text-align: center;
            text-decoration: none;
            font-size: 18px;
            line-height: 1.1;
            letter-spacing: 0.5px;
          }
        }
      }
    }
  }
  &__bannerLeague {
    position: relative;
    background-color: white;
    width: 100%;
    height: 300px;
    padding: 10px;
    &__content {
      .ThirdTime__bannerLeague__league {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 80px;
        img {
          width: 100%;
          height: 100%;
          padding: 15px;
        }
      }
    }
  }
  &__logo3er {
    position: relative;
    background-color: var(--bg-secondary);
    width: 100%;
    height: 300px;
    padding: 10px;
    &__content {
      .ThirdTime__logo3er__logo {
        display: flex;
        flex-wrap: wrap;

        img {
          width: 100%;
          height: 100%;
          padding: 50px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .ThirdTime {
    &__hero {
      &__content {
        .ThirdTime__content {
          &__top {
            height: 440px;
          }

          &__bottom {
            margin-top: -63px;
            &::before {
              content: '';
              position: absolute;
              z-index: 5;
              top: 0;
              left: 0;
              box-shadow: 0 5px 50px 70px var(--bg-secondary);
              height: 100%;
              width: 100%;
              pointer-events: none;
            }
            .Content__bottom__text {
              .Bottom__text {
                &__center {
                }
              }
            }
          }
        }
      }
    }
    &__custom {
      width: 100%;
      height: 500px;
      &__content {
        .Custom__content__photo {
          z-index: 5;
          margin-top: 30px;
          display: flex;
          img {
            z-index: 10;
            width: 100%;
            height: 100%;
            top: 8px;
            padding: 15px;
          }
        }
        .Custom__content__textInfo {
          padding: 15px;
          margin: 10px;
          p {
            line-height: 1.6;
            font-size: 18px;
            text-align: center;
            align-items: center;
            margin-top: 10px;
          }
        }
      }
    }
    &__ig {
      width: 100%;
      height: 1000px;
      padding: 10px;
      &__content {
        .ThirdTime__ig__textTitle {
          h1 {
            color: white;
            font-family: var(--font-bold);
            font-size: 1.5rem;
            letter-spacing: -0.5px;
            margin: 1.5rem 0;
            line-height: 0.9;
            text-align: center;
          }
          span {
            color: var(--text-secondary);
          }
        }
        .ThirdTime__ig__gallery {
          display: flex;
          flex-wrap: wrap;

          .igImg {
            width: 25%;

            img {
              width: 100%;
              height: 100%;
              padding: 10px;
            }
          }
          .igImgClick {
            left: 11px;
            a {
              font-family: var(--font-secondary-bold);
              color: var(--white);
              background-color: transparent;
              border: 2px solid var(--white);
              padding: 2.5rem 0;
              display: block;
              text-align: center;
              text-decoration: none;
              font-size: 16px;
              line-height: 1.1;
              letter-spacing: 0.5px;
            }
          }
        }
      }
    }
    &__bannerLeague {
      position: relative;
      background-color: white;
      width: 100%;
      height: 300px;
      padding: 10px;
      &__content {
        display: flex;
        justify-content: center;
        margin-top: -25px;
        .ThirdTime__bannerLeague__league {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          width: 70%;
          img {
            width: 100%;
            height: 100%;
            padding: 10px;
          }
        }
      }
    }
    &__logo3er {
      position: relative;
      background-color: var(--bg-secondary);
      width: 100%;
      height: 300px;
      padding: 10px;
      &__content {
        display: flex;
        justify-content: center;
        .ThirdTime__logo3er__logo {
          display: flex;
          flex-wrap: wrap;
          width: 50%;
          img {
            width: 100%;
            height: 100%;
            padding: 50px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .ThirdTime {
    &__hero {
      &__content {
        .ThirdTime__content {
          &__top {
            height: 495px;
          }

          &__bottom {
            padding: 1rem 1rem;
            background-color: var(--bg-secondary);
            position: relative;
            margin-top: -60px;
            &::before {
              content: '';
              position: absolute;
              z-index: 5;
              top: 0;
              left: 0;
              box-shadow: 0 5px 50px 70px var(--bg-secondary);
              height: 100%;
              width: 100%;
              pointer-events: none;
            }
            .Content__bottom__text {
              .Bottom__text {
                &__center {
                  span {
                    color: var(--white);
                    font-size: 20px;
                    margin-bottom: 0;
                    line-height: 1;
                    letter-spacing: 0.5px;
                  }

                  h1 {
                    font-size: 3rem;
                  }

                  p {
                    font-size: 26px;
                  }
                  span {
                    color: var(--text-secondary);
                  }
                }
              }
            }
          }
        }
      }
    }
    &__custom {
      width: 100%;
      height: 550px;
      &__content {
        .Custom__content__photo {
          z-index: 5;
          width: 80%;
          margin-top: 10px;
          position: relative;
          display: flex;
          left: 105px;
          img {
            z-index: 5;
            width: 100%;
            height: 100%;
            top: 8px;
            padding: 15px;
          }
        }
        .Custom__content__textInfo {
          padding: 15px;
          margin: 10px;
          p {
            font-size: 25px;
            margin-top: 5px;
          }
        }
      }
    }
    &__ig {
      height: 1110px;
      &__content {
        .ThirdTime__ig__textTitle {
          h1 {
            font-size: 1.5rem;
          }
          span {
            color: var(--text-secondary);
          }
        }
        .ThirdTime__ig__gallery {
          display: flex;
          flex-wrap: wrap;

          .igImg {
            width: 25%;
            img {
              width: 100%;
              height: 100%;
              padding: 10px;
            }
          }
          .igImgClick {
            width: 33.33%;
            border: 0px dotted lightgrey;
            padding: 0px;
            margin: 0px;
            align-items: center;
            display: flex;
            position: relative;
            left: 13px;
            a {
              padding: 4.5rem 0;
              font-size: 22px;
              letter-spacing: 0.5px;
            }
          }
        }
      }
    }
    &__bannerLeague {
      position: relative;
      background-color: white;
      width: 100%;
      height: 300px;
      padding: 10px;
      &__content {
        display: flex;
        justify-content: center;
        margin-top: -25px;
        .ThirdTime__bannerLeague__league {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          img {
            width: 70%;
            height: 100%;
            padding: 10px;
          }
        }
      }
    }
    &__logo3er {
      position: relative;
      background-color: var(--bg-secondary);
      width: 100%;
      height: 300px;
      padding: 10px;
      &__content {
        .ThirdTime__logo3er__logo {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          img {
            width: 40%;
            height: 100%;
            padding: 50px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .ThirdTime {
    &__hero {
      &__content {
        .ThirdTime__content {
          &__top {
            height: 800px;
          }

          &__bottom {
            padding: 1rem 1rem;
            background-color: var(--bg-secondary);
            position: relative;
            margin-top: -60px;
            &::before {
              content: '';
              position: absolute;
              z-index: 5;
              top: 0;
              left: 0;
              box-shadow: 0 5px 50px 70px var(--bg-secondary);
              height: 100%;
              width: 100%;
              pointer-events: none;
            }
            .Content__bottom__text {
              .Bottom__text {
                &__center {
                  span {
                    color: var(--white);
                    font-size: 20px;
                    margin-bottom: 0;
                    line-height: 1;
                    letter-spacing: 0.5px;
                  }

                  h1 {
                    display: flex;
                    justify-content: center;
                    position: relative;
                    align-items: center;
                    font-size: 50px;
                  }

                  p {
                    font-size: 30px;
                  }
                  span {
                    color: var(--text-secondary);
                  }
                }
              }
            }
          }
        }
      }
    }
    &__custom {
      height: auto;
      padding: 100px;
      &__content {
        .Custom__content__photo {
          margin-top: -100px;
          width: 80%;
          left: 140px;
          position: relative;

          img {
            z-index: 5;
            width: 100%;
            height: 100%;
          }
        }
        .Custom__content__textInfo {
          padding: 15px;
          margin: 10px;
          p {
            font-size: 25px;
          }
        }
      }
    }
    &__ig {
      width: 100%;
      height: 1000px;
      padding: 10px;
      &__content {
        .ThirdTime__ig__textTitle {
          h1 {
            color: white;
            font-family: var(--font-bold);
            font-size: 1.5rem;
            letter-spacing: -0.5px;
            margin: 1.5rem 0;
            line-height: 0.9;
            text-align: center;
          }
          span {
            color: var(--text-secondary);
          }
        }
        .ThirdTime__ig__gallery {
          display: flex;
          flex-wrap: wrap;
          width: 94%;
          padding: 159px;
          margin-top: -95px;
          align-items: center;
          position: absolute;
          left: 75px;

          .igImg {
            width: 250px;
            height: 100%;
            border: 0px dotted lightgrey;
            padding: 16px;

            img {
              width: 80%;
              height: 100%;
              padding: 0px;
              margin-top: -22px;
            }
          }
          .igImgClick {
            width: 335px;
            height: 100%;
            border: 0px dotted lightgrey;
            padding: 0px;
            margin-top: -22px;
            /* align-items: center; */
            /* display: flex; */
            /* position: relative; */
            left: 16px;
            a {
              padding: 1.9rem 0px;
              font-size: 20px;
              width: 52%;
            }
          }
        }
      }
    }
    &__bannerLeague {
      position: relative;
      background-color: white;
      width: 100%;
      height: 350px;
      padding: 10px;
      &__content {
        display: flex;
        justify-content: center;
        margin-top: -75px;
        .ThirdTime__bannerLeague__league {
          padding: 4.9rem 0px;
          font-size: 34px;
          width: 52%;

          img {
            width: 100%;
            height: 100%;
            padding: 10px;
          }
        }
      }
    }
    &__logo3er {
      width: 100%;
      height: 350px;
      padding: 10px;
      &__content {
        .ThirdTime__logo3er__logo {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          width: 30%;
          img {
            width: 100%;
            height: 100%;
            padding: 50px;
          }
        }
      }
    }
  }
}
