.Float__card {
  position: fixed;
  top: 92%;
  transform: translateY(-70%);
  right: 1rem;
  display: flex;
  flex-direction: row;
  z-index: 10;
  width: 195px;
  transition: all 250ms ease-in-out;
  &.out {
    opacity: 0;
    pointer-events: none;
  }

  &__square {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    height: 65px;
    width: 150px;
    padding: 0 1.5rem;
    padding-left: 0;
    background-color: var(--text-secondary);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    a {
      padding-top: 3px;
      color: var(--text-primary);
      text-align: center;
      font-family: var(--font-bold);
      text-transform: uppercase;
      text-decoration: none;
      font-size: 17px;
      line-height: 1;
    }
    label {
      color: var(--white);
      text-transform: uppercase;
      font-size: 14px;
      text-align: center;
    }
  }

  &__circle {
    height: 90px;
    width: 90px;
    border-radius: 50%;
    background-color: var(--text-secondary);
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: bottom;
    }
  }
}

@media screen and (min-width: 768px) {
  .Float__card {
    top: 93%;
    left: 1rem;
    right: inherit;
    z-index: 10;
  }
}
