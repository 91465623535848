.TeamForm__hero {
  z-index: 2;
  position: relative;
  &__content {
    .Hero__content {
      &__top {
        height: 190px;
        background-image: url('../../../statics/images/TeamCristal/header.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
      }
      &__bottom {
        height: 295px;
        padding: 2rem 1rem;
        background: url(../../../statics/images/TeamCristal/background-green.png);
        background-position: top center;
        background-size: cover;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .Content__bottom__logo {
          padding: 0 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          .Bottom__logo {
            &__center {
              position: absolute;
              top: -50px;

              img {
                max-width: 8rem;
              }
            }
            &__text {
              justify-content: center;
              display: flex;
              position: relative;
              padding: 0px 1rem;
              align-items: center;

              span {
                color: var(--white);
                font-size: 20px;
                margin-bottom: 0;
                line-height: 1;
                letter-spacing: 0.5px;
                text-align: center;
              }

              h1 {
                color: var(--text-secondary);
                text-shadow: 2px 2px 2px #000000;
                font-family: var(--font-bold);
                font-size: 3rem;
                letter-spacing: 0.5px;
                margin-bottom: 0;
                margin-top: 0.5em;
                text-align: center;
              }

              p {
                color: var(--white);
                text-shadow: 2px 2px 2px #000000;
                line-height: 1.6;
                font-size: 18px;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}

.TeamForm {
  position: relative;
  z-index: -1;
  &__competition {
    &__content {
      overflow: hidden;
      padding: 6rem 1rem 3rem;
      margin-top: -110px;
      min-height: 500px;
      background-color: var(--bg-primary);

      h1 {
        text-align: center;
        line-height: 1.6;
        font-size: 18px;
        color: #5ea641;
        font-family: var(--font-bold);
        margin: 0;
      }
      h2 {
        text-align: center;
        line-height: 1.6;
        font-size: 18px;
        margin: 0;
      }
      .Content__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;
        width: 100%;
        margin-top: 1rem;
        a {
          button {
            text-decoration: none;
            text-align: center;
            font-size: 0.9rem;
            letter-spacing: 1px;
            padding: 0.5rem 1rem;
            border: 1px solid var(--text-secondary);
            color: var(--text-secondary);
            font-family: var(--font-bold);
            background-color: transparent;
            border-radius: 5px;
            width: 160px;
            height: 50px;
          }
        }
      }
    }
    .Form__footer {
      height: 300px;
      padding: 0rem 1rem;
      background: url(../../../statics/images/TeamCristal/background-2-teamverano-min.png);
      background-position: center;
      background-size: cover;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      img {
        width: 100%;
        bottom: 0;
        height: auto;
        padding: 0;
        position: absolute;
      }
    }
    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 5;
      margin-top: 2rem;
      .Form__input__group {
        width: 100%;
        .Form__input {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;

          input {
            width: 100%;
            display: block;
            border: none;
            border-radius: 10px;
            padding: 20px;
            margin-bottom: 20px;
            outline: none;
            //transition: all 0.2s ease-in-out;
            text-decoration: none;
            font-size: 0.9rem;
            letter-spacing: 1px;
            border: 1px solid #de9e27;
            color: #de9e27;
            font-family: var(--font-bold);
            background-color: transparent;
            border-radius: 5px;
            height: 15px;
            &::placeholder {
              transition: all 0.2s ease-in-out;
              color: #de9e27;
            }
          }
        }
      }

      textarea {
        width: 100%;
        height: 150px;
        border: none;
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 20px;
        outline: none;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
        font-size: 0.9rem;
        letter-spacing: 1px;
        border: 1px solid #de9e27;
        color: #de9e27;
        font-family: var(--font-bold);
        background-color: transparent;
        border-radius: 5px;

        &::placeholder {
          transition: all 0.2s ease-in-out;
          color: #de9e27;
        }
      }

      .Form__action {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        button {
          margin-top: 0.25rem;
          width: 70%;
          text-transform: uppercase;
          font-size: 1rem;
          letter-spacing: 1px;
          padding: 0.75rem 0rem;
          border: unset;
          color: #fff;
          font-family: var(--font-bold);
          background-color: #de9e27;
          padding-top: 1rem;
          border-radius: 5px;
          cursor: pointer;
        }
      }
    }
    .Form__button {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
      a {
        button {
          text-decoration: none;
          text-align: center;
          font-size: 0.9rem;
          letter-spacing: 1px;
          padding: 0.5rem 1rem;
          border: 1px solid #de9e27;
          color: #de9e27;
          font-family: var(--font-bold);
          background-color: transparent;
          border-radius: 16px;
          width: 160px;
          height: 50px;
          cursor: pointer;
        }
      }
    }
    .Form-checkbox {
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-family: 'Poppins';
        text-decoration: none;
        color: #eb2a33;
        padding-left: 0.5rem;
        font-size: 0.9rem;
        display: inline-block;
      }
      a {
        padding-left: 0.5rem;
        font-size: 0.9rem;
        display: inline-block;
      }
    }
  }
}
.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 32em;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 5px;
  background: var(--bg-primary);
  color: var(--text-primary);
  font-family: inherit;
  font-size: 1rem;
}
.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #de9e27;
  color: #fff;
  font-size: 1em;
}

@media screen and (min-width: 768px) {
  .TeamForm__hero {
    &__content {
      .Hero__content {
        &__top {
          height: 450px;
        }
        &__bottom {
          height: 415px;
          padding: 2rem 1rem;
          background-position: bottom;

          .Content__bottom__logo {
            padding: 0 1rem;
            .Bottom__logo {
              &__center {
                top: -50px;

                img {
                  max-width: 12rem;
                  margin-top: 70px;
                }
              }
              &__text {
                span {
                  color: var(--white);
                  font-size: 20px;
                  letter-spacing: 0.5px;
                }

                h1 {
                  color: var(--text-secondary);
                  font-family: var(--font-bold);
                  font-size: 3rem;
                  letter-spacing: 0.5px;
                  margin-bottom: 0;
                  margin-top: 2.5em;
                  text-align: center;
                  position: sticky;
                  right: 550px;
                }

                p {
                  color: var(--white);
                  line-height: 1.6;
                  font-size: 18px;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }

  .TeamForm {
    position: relative;
    z-index: -1;
    &__competition {
      &__content {
        overflow: hidden;
        padding: 18rem 1rem 3rem;
        margin-top: -380px;
        min-height: 500px;

        h1 {
          font-size: 40px;
        }
        h2 {
          text-align: center;
          line-height: 1.6;
          font-size: 18px;
          margin: 0;
        }
        .Content__buttons {
          gap: 40px;
          width: 100%;
          margin-top: 1rem;
          a {
            button {
              font-size: 0.9rem;
              letter-spacing: 1px;
              padding: 0.5rem 1rem;
              border-radius: 5px;
              width: 160px;
              height: 50px;
            }
          }
        }
      }
      .Form__footer {
        height: 300px;
        padding: 21rem 1rem;

        img {
          width: 100%;
        }
      }
      form {
        z-index: 5;
        margin-top: 2rem;
        .Form__input__group {
          width: 100%;
          .Form__input {
            width: 100%;

            input {
              width: 300%;
            }
          }
        }

        textarea {
          width: 300%;
          height: 200px;
        }

        .Form__action {
          width: 100%;
          button {
            width: 70%;
            font-size: 1rem;
            letter-spacing: 1px;
            padding: 0.75rem 0rem;
            padding-top: 1rem;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
