.validate-container {
  height: 100vh;
  width: 100%;
  z-index: 50;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  background-image: url('../../statics/images/Validate/bg-validate.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 350ms ease-in-out;
  &.out {
    display: none;
  }

  .validate-content {
    background: rgba($color: #000000, $alpha: 0.65);
    padding: 2.5rem 1rem;
    border-radius: 25px;
    .validate-content-title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      h1 {
        color: var(--text-secondary);
        font-family: var(--font-secondary-bold);
        text-transform: uppercase;
        text-align: center;
        line-height: 1.1;
        margin: 0;
      }
      h2 {
        color: var(--text-secondary);
        font-family: var(--font-secondary);
        text-align: center;
        line-height: 1.1;
        margin-top: 0.75rem;
        margin-bottom: 0;
      }
    }

    .validate-content-form {
      .validate-form {
        .validate-content-form-input {
          display: flex;
          align-items: center;
          justify-content: center;
          input {
            width: 70px;
            height: 50px;
            font-size: 36px;
            margin-right: 10px;
            text-align: center;
            &:last-child {
              width: 130px;
              margin-right: 0;
            }
          }
        }

        .validate-content-form-button {
          margin-top: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          button {
            height: 45px;
            font-size: 18px;
            margin-right: 10px;
            text-transform: uppercase;
            padding: 0.35rem;
            width: 150px;
            font-family: var(--font-secondary-bold);
            color: var(--white);
            background: unset;
            border: 2px solid var(--white);
            padding: 0.5rem 0;
            display: block;
            text-align: center;
            text-decoration: none;
            font-size: 20px;
            line-height: 1.1;
            letter-spacing: 0.5px;
            cursor: pointer;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .validate-container {
    .validate-content {
      padding: 2rem;
      .validate-content-title {
        h1 {
          max-width: 75%;
        }
      }
    }
  }
}
