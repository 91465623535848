.History {
  &__hero {
    &__content {
      .History__content {
        &__top {
          height: 500px;
          background-image: url('../../statics/images/NuestrasCervezas//product-mobile.png');
          background-position: center -5rem;
          background-size: cover;
          background-repeat: no-repeat;
          position: relative;
        }

        &__bottom {
          padding: 0rem 1rem;
          background-color: var(--bg-secondary);
          position: relative;
          &::before {
            content: '';
            position: absolute;
            z-index: 5;
            top: 0;
            left: 0;
            box-shadow: 0 -100px 50px 70px var(--bg-secondary);
            height: 100%;
            width: 100%;
            pointer-events: none;
          }
          .Content__bottom__text {
            .Bottom__text {
              &__center {
                span {
                  color: var(--white);
                  font-size: 20px;
                  margin-bottom: 0;
                  line-height: 1;
                  letter-spacing: 0.5px;
                }

                h1 {
                  color: var(--text-secondary);
                  font-family: var(--font-bold);
                  font-size: 3rem;
                  letter-spacing: 0.5px;
                  margin: 1.5rem 0;
                  line-height: 0.9;
                }

                p {
                  color: var(--white);
                  line-height: 1.6;
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
    }
  }

  &__timeline {
    z-index: 5;
    position: relative;
    background-color: var(--bg-seconday);
    &__content {
      .History__timeline__list {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          left: 2rem;
          top: 3rem;
          height: calc(100% - 130px - 3rem);
          width: 2px;
          background-color: var(--white);
        }

        li {
          &:last-child {
            margin-bottom: 0;
          }

          .Timeline__content {
            &__rigth {
              .Card__timeline {
                .Card__content {
                  display: flex;
                  &__text {
                    margin-left: 3.5rem;
                    padding: 2rem 1.5rem;
                    span {
                      font-size: 2rem;
                      font-family: var(--font-bold);
                      color: var(--text-secondary);
                      position: relative;
                      &::before {
                        content: '';
                        position: absolute;
                        left: -3.9rem;
                        top: 50%;
                        transform: translateY(-50%);
                        height: 30px;
                        width: 30px;
                        background-color: var(--white);
                        border-radius: 50%;
                      }
                    }

                    p {
                      margin: 0;
                      margin-top: 0.35rem;
                      font-size: 16px;
                      color: var(--text-primary);
                      line-height: 1.5;
                    }
                  }

                  &__image {
                    height: 150px;
                    img {
                      height: 100%;
                      width: 100%;
                      object-position: center;
                      object-fit: cover;
                    }
                  }
                }
              }
            }
          }

          &.green {
            background-color: var(--bg-secondary);
            .Timeline__content {
              &__rigth {
                .Card__timeline {
                  .Card__content {
                    &__text {
                      p {
                        color: var(--white);
                      }
                    }
                  }
                }
              }
            }
          }

          &.gray {
            background-color: var(--bg-primary);
          }

          &.top {
            .Timeline__content {
              &__rigth {
                .Card__timeline {
                  .Card__content {
                    flex-direction: column-reverse;
                  }
                }
              }
            }
          }

          &.bottom {
            .Timeline__content {
              &__rigth {
                .Card__timeline {
                  .Card__content {
                    flex-direction: column;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .History {
    &__hero {
      &__content {
        .History__content {
          &__bottom {
            padding: 0 2rem;
            .Content__bottom__text {
              .Bottom__text {
                &__center {
                  p {
                    max-width: 65%;
                  }
                }
              }
            }
          }
        }
      }
    }

    &__timeline {
      &__content {
        .History__timeline__list {
          background-color: var(--bg-primary);
          padding-bottom: 6rem;
          &::before {
            left: 3rem;
          }

          li {
            .Timeline__content {
              &__rigth {
                .Card__timeline {
                  .Card__content {
                    &__text {
                      padding: 2rem;
                      span {
                        &::before {
                          left: -3.4rem;
                        }
                      }
                    }

                    &__image {
                      height: 300px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .History {
    &__hero {
      &__content {
        .History__content {
          &__top {
            background-image: url('../../statics/images/Home/hero-desktop.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .History {
    &__hero {
      padding-bottom: 0rem;
      &__content {
        .History__content {
          &__top {
            height: 550px;
          }
          &__bottom {
            .Content__bottom__text {
              .Bottom__text {
                &__center {
                  max-width: 1200px;
                  margin: 0 auto;
                }
              }
            }
          }
        }
      }
    }

    &__timeline {
      &__content {
        .History__timeline__list {
          background-color: var(--bg-primary);
          padding-bottom: 6rem;
          &::before {
            left: 7rem;
          }

          li {
            .Timeline__content {
              &__rigth {
                .Card__timeline {
                  .Card__content {
                    &__text {
                      max-width: 1200px;
                      width: 100%;
                      margin: 0 auto;
                      padding: 2rem;
                      padding-bottom: 4rem;
                      span {
                        font-size: 3rem;
                        &::before {
                          left: -3.4rem;
                        }
                      }

                      p {
                        font-size: 22px;
                      }
                    }

                    &__image {
                      height: 530px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
