@font-face {
  font-family: 'Geomanist';
  src: url('../statics/fonts/Geomanist/Geomanist-Regular.otf');
}

@font-face {
  font-family: 'Geomanist-Bold';
  src: url('../statics/fonts/Geomanist/Geomanist-Bold.ttf');
}

@font-face {
  font-family: 'Myriad';
  src: url('../statics/fonts/Myriad/Myriad-Regular.ttf');
}

@font-face {
  font-family: 'Myriad-Bold';
  src: url('../statics/fonts/Myriad/Myriad-Bold.OTF');
}

@font-face {
  font-family: 'Myriad-SemiBold';
  src: url('../statics/fonts/Myriad/Myriad-SemiBold.OTF');
}
