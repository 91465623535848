.Header {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
  &__content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.25rem;
    padding-right: 1rem;

    &__logo {
      max-width: 200px;
      img {
        width: 65%;
      }
    }

    &__button {
      margin-bottom: 2rem;
      button {
        background: transparent;
        border: none;
        height: 20px;
        width: 30px;
        position: relative;
        padding: 0;
        span {
          left: 0;
          height: 4px;
          width: 100%;
          transition: all 250ms ease-in-out;
          position: absolute;
          background-color: var(--bg-secondary);
          &:first-child {
            top: 0;
          }

          &:nth-child(2) {
            top: 50%;
            transform: translateY(-50%);
          }

          &:last-child {
            bottom: 0;
            width: 70%;
          }
        }
      }
    }
  }

  &.active {
    .Header__content {
      &__button {
        button {
          span {
            background-color: var(--bg-primary);
            &:first-child {
              top: 50%;
              transform: rotate(45deg) translateY(-50%);
            }

            &:nth-child(2) {
              opacity: 0;
              transform: translateX(-15px);
            }

            &:last-child {
              width: 100%;
              bottom: 50%;
              transform: rotate(-45deg) translateY(50%);
            }
          }
        }
      }
    }
  }
}

.Header__navigation {
  height: 100vh;
  opacity: 0;
  width: 100%;
  position: absolute;
  z-index: 9;
  background-color: var(--bg-secondary);
  transition: all 250ms ease-in-out;
  overflow: hidden;
  pointer-events: none;
  &__bgImg {
    position: absolute;
    bottom: 0;
    right: -9.5rem;
    width: 350px;
    height: 450px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }

  &__links {
    margin: 0;
    padding: 0 2rem;
    padding-top: 45%;
    z-index: 4;
    position: relative;
    li {
      list-style: none;
      margin-bottom: 1.5rem;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        text-decoration: none;
        color: var(--white);
        font-size: 2rem;
        font-family: var(--font-secondary-bold);
        letter-spacing: 1px;
      }
    }
    .fb {
      width: 40px;
    }
    .ig {
      width: 40px;
      margin-left: 10px;
    }
  }

  &.active {
    opacity: 1;
    pointer-events: all;
  }
}

@media screen and (min-width: 768px) {
  .Header__content {
    padding-right: 1rem;
    &__logo {
      width: 180px;
    }

    &__button {
      button {
        display: none;
      }
    }
  }

  .Header__navigation {
    height: 125px;
    opacity: 1;
    background: unset;
    pointer-events: all;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20%;
    z-index: 10;
    &__bgImg {
      display: none;
    }

    &__links {
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 0.5rem 1rem;
      padding-top: 0.7rem;
      margin-right: 1rem;
      border-radius: 8px;
      background-color: var(--white);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      li {
        margin-right: 10px;
        margin-bottom: 0;
        &:last-child {
          margin-right: 0;
        }
        a {
          color: var(--text-secondary);
          font-size: 1rem;
          &.is-active {
            color: var(--text-primary);
          }
        }
      }
    }
    .fb {
      width: 25px;
    }
    .ig {
      width: 25px;
    }
  }
}

@media (min-width: 1024px) {
  .Header__content {
    &__logo {
      width: 300px;
      max-width: unset;
      padding-top: 0.5rem;
    }
  }

  .Header__navigation {
    top: -0.5rem;
    &__links {
      li {
        margin-right: 15px;
        a {
          font-size: 1.2rem;
          letter-spacing: unset;
        }
      }
    }
  }
}
