.Footer {
  &__content {
    &__primary {
      padding: 0 1rem;
      background-image: url('../../statics/images/Footer/bg-saleSection.png');
      background-repeat: no-repeat;
      background-position: -42rem 0rem;
      height: 660px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      background-size: cover;
      .Content__primary__text {
        padding: 0 1rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 5rem;
        
        h3 {
          margin-top: 0;
          color: var(--white);
          font-size: 2rem;
          text-align: center;
          line-height: 1.2;
          font-family: var(--font-secondary-bold);
        }

        a {
          font-family: var(--font-secondary-bold);
          color: var(--white);
          background-color: transparent;
          border: 2px solid var(--white);
          padding: 0.5rem 0;
          display: block;
          text-align: center;
          text-decoration: none;
          width: 150px;
          font-size: 18px;
          line-height: 1.1;
          letter-spacing: 0.5px;
        }
      }

      .Content__primary__image {
        position: relative;
        img {
          height: 500px;
          position: absolute;
          bottom: -100px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    &__secondary {
      height: 400px;
      padding: 4rem 1rem;
      position: relative;
      background-image: url('../../statics/images/Footer/bg-footer.png');
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      background-position: left;
      .Content__secondary__menu {
        display: none;
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            font-family: var(--font-bold);
            margin-bottom: 0.5rem;
            &:last-child {
              margin-bottom: 0;
            }
            a {
              text-decoration: none;
              color: var(--text-primary);
            }
          }
        }
      }

      .Content__secondary__logo {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 0 1rem;
        img {
          width: 150px;
        }

        p {
          text-align: center;
          line-height: 1.5;
        }
        button {
          cursor: pointer;
          font-family: var(--font-secondary-bold);
          color: var(--text-primary);
          background-color: transparent;
          border: 2px solid var(--text-primary);
          padding: 0.5rem 0;
          display: block;
          text-align: center;
          text-decoration: none;
          width: 170px;
          font-size: 15px;
          line-height: 1.1;
          letter-spacing: 0.5px;
          margin-top: 20px;
        }
      }
      .modalSucess {
        background-color: rgba(0, 0, 0, 0.7);
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        display: none;
        justify-content: center;
        align-items: center;
        overflow: auto;

        &.active {
          display: flex;
        }
      }

      .Content__secondary__rrss {
        padding: 0 1rem;
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          li {
            height: 50px;
            width: 50px;
            a {
              display: block;
              width: 100%;
              height: 100%;
              text-decoration: none;
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .Footer {
    &__content {
      &__primary {
        height: 300px;
        flex-direction: row-reverse;
        background-position: center;
        .Content__primary__image {
          max-width: 45%;
          width: 100%;
          height: 100%;
          img {
            height: 450px;
            bottom: -80px;
            left: 70%;
            z-index: 5;
          }
        }

        .Content__primary__text {
          max-width: 55%;
          padding-top: 0;
          align-items: flex-start;
          h3 {
            text-align: left;
            margin-bottom: 1rem;
            font-size: 2.5rem;
          }
        }
      }

      &__secondary {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 300px;
        padding: 3rem 2rem;
        z-index: 6;
        .Content__secondary__menu {
          display: block;
        }

        .Content__secondary__rrss {
          padding: 0;
          ul {
            li {
              height: 30px;
              width: 30px;
              margin-bottom: 0;
              margin-right: 1rem;
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .Footer {
    z-index: 8;
    position: relative;
  }
}
