.Process {
  &__hero {
    &__content {
      .Process__content {
        &__top {
          height: 440px;
          background-image: url('../../statics/images/Amigable/banner-amigable.png');
          background-position: center 0rem;
          background-size: cover;
          background-repeat: no-repeat;
          position: relative;
        }

        &__bottom {
          padding: 1rem 1rem;
          background-color: steps;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            z-index: 5;
            top: 0;
            left: 0;
            box-shadow: 0 -80px 50px 70px var(--bg-secondary);
            height: 100%;
            width: 100%;
            pointer-events: none;
          }
          .Content__bottom__text {
            .Bottom__text {
              &__center {
                span {
                  color: var(--white);
                  font-size: 20px;
                  margin-bottom: 0;
                  line-height: 1;
                  letter-spacing: 0.5px;
                }

                h1 {
                  color: var(--text-secondary);
                  font-family: var(--font-bold);
                  font-size: 2rem;
                  letter-spacing: -0.5px;
                  margin: 1.5rem 0;
                  line-height: 0.9;
                  text-align: center;
                }

                p {
                  color: var(--white);
                  line-height: 1.6;
                  font-size: 18px;
                  text-align: center;
                }
                span {
                  color: var(--text-secondary);
                }
              }
            }
          }
        }
      }
    }
  }

  &__steps {
    position: relative;
    z-index: 5;
    background-color: var(--bg-primary);
    &__content {
      ul {
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          display: flex;
          flex-direction: row;
          width: 100%;
          .Steps__content__left {
            max-width: 50%;
            width: 100%;
            height: 250px;
            position: relative;
            padding: 0 0.5rem;
            img {
              position: absolute;
            }
          }

          .Steps__content__right {
            max-width: 50%;
            width: 100%;
            padding: 1rem 0;
            padding-right: 1rem;
            margin-top: 0px;
            display: flex;
            align-items: center;
            h2 {
              font-family: var(--font-bold);
              letter-spacing: 1px;
              font-size: 15px;
              justify-content: center;
              margin: 0;
            }

            p {
              display: none;
              color: white;
            }
          }

          .Steps__content__modal {
            position: absolute;
            background: #faba3c;
            margin: 0 2rem;
            opacity: 0;
            pointer-events: none;
            transition: all 250ms ease-in-out;
            transform: translateY(-10%);
            border: 4px solid var(--bg-secondary);
            .Content__modal__close {
              display: flex;
              justify-content: flex-end;
              align-items: flex-end;
              padding-top: 6px;

              button {
                background: unset;
                border: unset;
                font-size: 30px;
                color: var(--text-primary);
                font-family: var(--font-bold);
              }
            }

            .Content__modal__text {
              padding: 1rem;
              p {
                line-height: 1.4;
                margin: 0;
              }
            }

            &.open {
              opacity: 1;
              pointer-events: all;
            }
          }

          &:first-child {
            background-color: var(--bg-primary);
            padding-top: 0rem;

            .Steps__content__left {
              img {
                top: 60px;
                left: 2rem;
                width: 150px;
              }
            }

            .Steps__content__right {
              h2 {
                color: var(--bg-secudary);
              }
            }
          }

          &:nth-child(2) {
            .Steps__content__left {
              img {
                top: 34%;
                left: 55%;
                width: 170px;
                transform: translateX(-50%);
              }
            }
          }

          &:nth-child(3) {
            .Steps__content__left {
              height: 200px;
              img {
                top: -41%;
                left: 50%;
                width: 160px;
                transform: translateX(-50%);
              }
            }
          }

          &:nth-child(4) {
            .Steps__content__left {
              height: 330px;
              img {
                top: 5%;
                width: 130px;
                left: 20px;
              }
            }
          }

          &:nth-child(5) {
            .Steps__content__left {
              height: 150px;
              img {
                top: -2rem;
                left: 50%;
                width: 130px;
                transform: translateX(-50%);
                height: 270px;
              }
            }
          }

          &:nth-child(6) {
            .Steps__content__left {
              height: 250px;
              img {
                top: 3rem;
                left: 50%;
                width: 150px;
                transform: translateX(-50%);
                height: 270px;
              }
            }
          }

          &:nth-child(7) {
            .Steps__content__left {
              height: 200px;
              img {
                top: -3rem;
                left: 50%;
                width: 85%;
                transform: translateX(-50%);
                height: 325px;
              }
            }
          }

          &:nth-child(8) {
            .Steps__content__left {
              height: 250px;
              img {
                top: 0.5rem;
                left: 37%;
                width: 90%;
                transform: translateX(-50%);
                height: 310px;
              }
            }
          }

          &:nth-child(9) {
            .Steps__content__left {
              height: 200px;
              img {
                top: 0.5rem;
                left: 10%;
                width: 80%;
              }
            }
          }

          &:nth-child(10) {
            .Steps__content__left {
              height: 200px;
              img {
                top: -62%;
                left: 39.5%;
                width: 70%;
                transform: translateX(-50%);
                height: 322px;
              }
            }
          }

          &:nth-child(11) {
            .Steps__content__left {
              height: 250px;
              img {
                top: 50%;
                left: -2rem;
                width: 100%;
                transform: translateY(-50%);
              }
            }
          }

          &:nth-child(12) {
            .Steps__content__left {
              height: 250px;
              img {
                top: 0%;
                left: 50%;
                width: 70%;
                transform: translateX(-50%);
                height: 100%;
              }
            }
          }

          &:nth-child(13) {
            .Steps__content__left {
              height: 250px;
              img {
                top: 0%;
                left: 50%;
                width: 70%;
                transform: translateX(-50%);
                height: 100%;
              }
            }
          }

          &.green {
            background-color: #1b5d00;
            .Steps__content__right {
              h2 {
                color: var(--white);
              }
            }
          }

          &.gray {
            .Steps__content__right {
              p {
                color: var(--text-primary);
              }
            }
          }

          &.white {
            background: rgb(226, 247, 255);
            background: linear-gradient(
              45deg,
              rgba(226, 247, 255, 1) 0%,
              rgba(189, 237, 255, 1) 85%
            );
            .Steps__content__right {
              h2 {
                color: #2c84b5;
              }

              p {
                color: #2c84b5;
              }
            }
          }

          &.yellow {
            background-color: var(--text-secondary);
            .Steps__content__right {
              h2 {
                color: var(--text-primary);
              }

              p {
                color: var(--text-primary);
              }
            }
          }
        }
      }
    }
  }
}
.SubFooter {
  width: 100%;
  background-color: #1b5d00;
  height: 650px;
  padding: 36px;

  h1 {
    color: var(--text-secondary);
    font-family: var(--font-bold);
    font-size: 2rem;
    letter-spacing: 0.5px;
    margin: 0.5rem 27px;
    line-height: 0.9;
    text-align: center;
  }
  P {
    color: var(--white);
    line-height: 1.6;
    font-size: 15px;
    text-align: center;
    margin: 1.5rem 0;
  }
  span {
    color: var(--text-secondary);
    font-family: var(--font-bold);
    font-size: 2rem;
    letter-spacing: 0.5px;
    margin: 1.5rem 0;
    line-height: 0.9;
    text-align: center;
  }
}

/* .Click {
    width: 100%;
    background-color: var(--bg-primary);
    height: 100px;
    padding-top: 1px;
    h1 {
      color: var(--text-primary);
      font-family: var(--font-bold);
      font-size: 1rem;
      letter-spacing: 0.5px;
      margin: 1.5rem 0;
      line-height: 0.9;
      text-align: center;
    }
    span {
      color: var(--text-secondary);
      font-family: var(--font-bold);
      font-size: 1rem;
      letter-spacing: 0.5px;
      margin: 1.5rem 0;
      line-height: 0.9;
      text-align: center;
    }
  } */

@media screen and (min-width: 768px) {
  .Process {
    &__hero {
      &__content {
        .Process__content {
          &__top {
          }

          &__bottom {
            padding: 0 2rem;
            .Content__bottom__text {
              padding: 10px;
              .Bottom__text {
                &__center {
                  p {
                    max-width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }

    &__steps {
      &__content {
        ul {
          li {
            .Steps__content__modal {
              display: none !important;
            }

            padding: 0 2rem;
            &:first-child {
              padding-top: 3rem;
              .Steps__content {
                &__left {
                  img {
                    left: 35%;
                    transform: translateX(-50%);
                  }
                }
              }
            }

            &:nth-child(2) {
              .Steps__content {
                &__left {
                  img {
                    left: 30%;
                  }
                }
              }
            }

            &:nth-child(3) {
              .Steps__content {
                &__left {
                  img {
                    left: 30%;
                    width: 150px;
                  }
                }
              }
            }

            &:nth-child(4) {
              .Steps__content {
                &__left {
                  img {
                    left: 10%;
                    margin-top: -10px;
                  }
                }
              }
            }

            &:nth-child(5) {
              .Steps__content {
                &__left {
                  img {
                    left: 44%;
                    width: 150px;
                  }
                }
              }
            }

            &:nth-child(6) {
              .Steps__content {
                &__left {
                  img {
                    left: 40%;
                  }
                }
              }
            }

            &:nth-child(7) {
              .Steps__content {
                &__left {
                  img {
                    left: 45%;
                    width: 65%;
                    height: 285px;
                  }
                }
              }
            }

            &:nth-child(8) {
              .Steps__content {
                &__left {
                  img {
                    left: 32%;
                    width: 100%;
                    height: 400px;
                  }
                }
              }
            }

            &:nth-child(9) {
              .Steps__content {
                &__left {
                  img {
                    top: 1.3rem;
                    width: 73%;
                  }
                }
              }
            }

            &:nth-child(10) {
              .Steps__content {
                &__left {
                  img {
                    top: -26%;
                    width: 100%;
                    height: 240px;
                  }
                }
              }
            }

            &:nth-child(11) {
              .Steps__content {
                &__left {
                  img {
                    width: 90%;
                  }
                }
              }
            }

            &:nth-child(12) {
              .Steps__content {
                &__left {
                  img {
                    height: 90%;
                  }
                }
              }
            }

            &:last-child {
              padding-bottom: 4rem;
            }

            .Steps__content {
              &__left {
                max-width: 35%;
              }

              &__right {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                max-width: 65%;
                margin-top: 50px;
                p {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .Process {
    &__hero {
      &__content {
        .Process__content {
          &__top {
            background-image: url('../../statics/images/Amigable/banner-amigable.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }

          &__bottom {
            padding: 0 2rem;
            .Content__bottom__text {
              .Bottom__text {
                &__center {
                  h1 {
                    padding-top: 10px;
                    font-size: 50px;
                  }
                  p {
                    max-width: 100%;
                    font-size: 25px;
                    span {
                        font-size: 25px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &__steps {
      &__content {
        ul {
          li {
            padding: 0 2rem;
            .Steps__content__modal {
              display: none !important;
            }

            &:last-child {
              padding-bottom: 4rem;
            }

            .Steps__content {
              &__left {
                max-width: 35%;
              }

              &__right {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                max-width: 65%;

                p {
                  display: block;
                  font-size: 12px;
                  line-height: 1.4;
                }
                h2 {
                  margin-top: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.SubFooter {
  h1 {
  }
  P {
  }
  span {
  }
}

.Click {
  h1 {
  }
  span {
  }
}

@media screen and (min-width: 1200px) {
  .Process {
    &__hero {
      &__content {
        .Process__content {
          &__top {
            height: 550px;
          }

          &__bottom {
            .Content__bottom__text {
              .Bottom__text {
                &__center {
                  max-width: 1200px;
                  margin: 0 auto;
                }
              }
            }
          }
        }
      }
    }

    &__steps {
      &__content {
        ul {
          li {
            .Steps__content {
              &__right {
                h2 {
                  font-size: 2.5rem;
                }
                p {
                  font-size: 20px;
                }
              }
            }

            &:first-child {
              padding-top: 3rem;
              .Steps__content {
                &__left {
                  img {
                    left: 50%;
                    width: 200px;
                    transform: translateX(-50%);
                  }
                }
              }
            }

            &:nth-child(2) {
              .Steps__content {
                &__left {
                  img {
                    left: 50%;
                    width: 200px;
                    top: 17px;
                  }
                }
              }
            }

            &:nth-child(3) {
              .Steps__content {
                &__left {
                  img {
                    left: 50%;
                    top: -129px;
                    width: 200px;
                  }
                }
              }
            }

            &:nth-child(4) {
              .Steps__content {
                &__left {
                  img {
                    left: 50%;
                    transform: translateX(-50%);
                  }
                }
              }
            }

            &:nth-child(5) {
              .Steps__content {
                &__left {
                  img {
                    left: 55%;
                    width: 200px;
                  }
                }
              }
            }

            &:nth-child(6) {
              .Steps__content {
                &__left {
                  img {
                    left: 50%;
                    width: 200px;
                  }
                }
              }
            }

            &:nth-child(7) {
              .Steps__content {
                &__left {
                  img {
                    left: 50%;
                    width: 65%;
                    height: 230px;
                  }
                }
              }
            }

            &:nth-child(8) {
              .Steps__content {
                &__left {
                  img {
                    left: 43%;
                  }
                }
              }
            }

            &:nth-child(9) {
              .Steps__content {
                &__left {
                  img {
                    top: 3rem;
                    width: 230px;
                    left: 30%;
                  }
                }
              }
            }

            &:nth-child(10) {
              .Steps__content {
                &__left {
                  img {
                    top: -27.3%;
                    width: 100%;
                    height: 300px;
                    left: 50%;
                  }
                }
              }
            }

            &:nth-child(11) {
              .Steps__content {
                &__left {
                  img {
                    width: 350px;
                  }
                }
              }
            }

            &:nth-child(12) {
              .Steps__content {
                &__left {
                  img {
                    height: 90%;
                  }
                }
              }
            }

            &:last-child {
              .Steps__content {
                &__left {
                  img {
                    width: 150px;
                    top: 35px;
                    left: 230px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .SubFooter {
    h1 {
      font-size: 3rem;
      margin-top: 60px;
      margin-bottom: 30px;
    }
    P {
      font-size: 25px;
      margin: 10px;
    }
    span {
    }
  }

  .Click {
    height: 150px;

    h1 {
      font-size: 1.5rem;
      margin: 2.5rem 0;
    }
    span {
      font-size: 1.5rem;
      line-height: -1rem;
    }
  }
}
