.OurBeer__hero {
  &__content {
    .Hero__content {
      &__top {
        height: 500px;
        background-image: url('../../statics/images/NuestrasCervezas/product-mobile.png');
        background-position: center -5rem;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
      }

      &__bottom {
        padding: 0rem 1rem;
        background-color: var(--bg-secondary);
        position: relative;
        &::before {
          content: '';
          position: absolute;
          z-index: 5;
          top: 0;
          left: 0;
          box-shadow: 0 -80px 50px 80px var(--bg-secondary);
          height: 100%;
          width: 100%;
          pointer-events: none;
        }
        .Content__bottom__text {
          .Bottom__text {
            &__left {
              span {
                color: var(--white);
                font-size: 20px;
                margin-bottom: 0;
                line-height: 1;
                letter-spacing: 0.5px;
              }

              h1 {
                color: var(--text-secondary);
                font-family: var(--font-bold);
                font-size: 3rem;
                letter-spacing: 0.5px;
                margin-bottom: 0;
                margin-top: 0.5em;
              }

              p {
                color: var(--white);
                line-height: 1.6;
                font-size: 18px;
              }
            }

            &__right {
              ul {
                margin: 0;
                padding: 0;
                li {
                  list-style: none;
                  color: var(--white);
                  margin-bottom: 0.25rem;
                  letter-spacing: 0.5px;
                  span {
                    font-family: var(--font-bold);
                  }
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.OurBeer {
  &__lager,
  &__zero {
    &__content {
      padding: 3rem 1rem;
      padding-top: 12rem;
      min-height: 500px;
      background-color: var(--bg-primary);
      z-index: 6;
    }
  }
}

.AboutOurBeer__lager {
  background-color: var(--bg-primary);
  padding-bottom: 4rem;
  &__banner {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../statics/images/NuestrasCervezas/fondoingre.png');
    background-position: left center;
    background-size: cover;
    -webkit-box-shadow: inset 0px 0px 100px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: inset 0px 0px 100px -10px rgba(0, 0, 0, 0.75);
    box-shadow: inset 0px 0px 100px -10px rgba(0, 0, 0, 0.75);
    .Lager__content__title {
      padding: 0 1rem;
      h1 {
        margin: 0;
        text-align: center;
        font-size: 3rem;
        line-height: 1.1;
        font-family: var(--font-bold);
        color: var(--white);
      }
    }
  }

  &__text {
    padding: 2rem 1rem;
    padding-bottom: 2rem;
    .Lager__content__text {
      p {
        text-align: center;
        line-height: 1.6;
        font-size: 18px;
      }
    }
  }
}

.AboutOurBeer__zero {
  &__content {
    .BeerZero__content {
      &__top {
        height: 395px;
        background-image: url('../../statics/images/NuestrasCervezas/zero_Raddler.png');
        background-position: center 0rem;
        background-repeat: no-repeat;
        position: relative;
      }

      &__bottom {
        padding: 0rem 1rem;
        background-color: var(--bg-tertiary);
        position: relative;
        &::before {
          content: '';
          position: absolute;
          z-index: 5;
          top: 0;
          left: 0;
          box-shadow: 0 30px 50px 120px var(--bg-tertiary);
          height: 100%;
          width: 100%;
          pointer-events: none;
        }
        .Content__bottom__text {
          .Bottom__text {
            &__left {
              span {
                color: var(--white);
                font-size: 20px;
                margin-bottom: 0;
                line-height: 1;
                letter-spacing: 0.5px;
              }

              h1 {
                color: var(--text-secondary);
                font-family: var(--font-bold);
                font-size: 3rem;
                letter-spacing: 0.5px;
                margin-bottom: 0;
                margin-top: 0.5em;
              }

              p {
                color: var(--white);
                line-height: 1.6;
                font-size: 18px;
              }
            }

            &__right {
              ul {
                margin: 0;
                padding: 0;
                li {
                  list-style: none;
                  color: var(--white);
                  margin-bottom: 0.25rem;
                  letter-spacing: 0.5px;
                  span {
                    font-family: var(--font-bold);
                  }
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .OurBeer__hero {
    &__content {
      .Hero__content {
        &__bottom {
          padding: 0 2rem;
          &::before {
            box-shadow: 0 30px 50px 120px var(--bg-secondary);
          }
          .Content__bottom__text {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            .Bottom__text__left {
              max-width: 47%;
              width: 50%;
            }

            .Bottom__text__right {
              max-width: 47%;
              width: 100%;
            }
          }
        }
      }
    }
  }

  .OurBeer {
    &__lager,
    &__zero {
      &__content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 2rem;
        padding-top: 12rem;
      }
    }
  }

  .AboutOurBeer__lager {
    &__text {
      .Lager__content__text {
        max-width: 768px;
        width: 80%;
        margin: 0 auto;
      }
    }
  }

  .AboutOurBeer__zero {
    &__content {
      .BeerZero__content {
        /*  &__top {
        } */

        &__bottom {
          padding: 0 2rem;
          .Content__bottom__text {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            .Bottom__text {
              &__left {
                max-width: 47%;
                width: 100%;
              }

              &__right {
                max-width: 47%;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .OurBeer__hero {
    &__content {
      .Hero__content {
        &__top {
          background-image: url('../../statics/images/Home/hero-desktop.jpg');
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }

  .AboutOurBeer__lager {
    background-color: var(--bg-primary);
    padding: 2rem 0;
    /* &__banner {
      .Lager__content__title {
        h1 {
        }
      }
    } */

    &__text {
      .Lager__content__text {
        p {
          font-size: 20px;
        }
      }
    }
  }

  .AboutOurBeer__zero {
    &__content {
      .BeerZero__content {
        &__top {
          height: 450px;
          background-image: url('../../statics/images/NuestrasCervezas/cristalzero-desktop.jpg');
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .OurBeer__hero {
    &__content {
      .Hero__content {
        &__top {
          height: 550px;
        }
        &__bottom {
          .Content__bottom__text {
            max-width: 1200px;
            margin: 0 auto;
          }
        }
      }
    }
  }

  .AboutOurBeer__zero {
    &__content {
      .BeerZero__content {
        &__top {
          height: 450px;
          background-image: url('../../statics/images/NuestrasCervezas/cristalzero-desktop.jpg');
        }
        &__bottom {
          .Content__bottom__text {
            max-width: 1200px;
            margin: 0 auto;
          }
        }
      }
    }
  }
}
